import { Vue, Component, Watch } from 'vue-property-decorator';
import { Action, State as StateClass, Getter } from 'vuex-class';
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import { IdentRouteNames } from '@/modules/identification/identRouteNames';
import { IdentifcationPath } from '@/helpers/checkout';
import 'details-polyfill';
import Loader from '@/components/common/loader/Loader.vue';
import { State } from '@/store/models';
import iDinLogo from '../../../../assets/logos/idin.svg?inline';
import { name } from '../../../../../whitelabel.config';

@Component({
  components: {
    Loader,
    iDinLogo,
  },
})

export default class IdentificationIdin extends Vue {
  issuerId: string = '';
  showError: boolean = false;
  companyName = name;

  @Action idinTransaction!: Function;
  @Action idinGetStatus!: Function;
  @Action(addToastMessage) addToastMessage!: Function;

  @StateClass idin!: State['idin'];
  @StateClass((state: State): any => state.idinIssuers && state.idinIssuers.Directory.Country) idinIssuers!: State['idinIssuers'];
  @StateClass user!: State['user'];
  @StateClass operations!: State['operations'];

  @Getter isInvestor!: boolean;

  @Watch('operations')
  onOperationsChanged(newOperations: State['operations'], oldOperations: State['operations']): void {
    if ((oldOperations.name === 'IDIN_TRANSACTION_PROCESSING' || oldOperations.name === 'IDIN_GET_STATUS_PROCESSING')
      && (newOperations.name === 'IDIN_TRANSACTION_ERROR' || newOperations.name === 'IDIN_GET_STATUS_ERROR')) {
      this.addToastMessage({
        text: this.$t('identification.idin.idinError'),
        type: 'danger',
      });
    }
  }

  /**
   * Idin process:
   * First we redirect to the url received url by handleIdinTransaction
   * The user will allow in his/her bank page to send to the merchant the data
   * Then we call iDin again to retrieve the actual data
   */
  @Watch('idin', { deep: true })
  onIdinTransactionChanged(newIdin: State['idin']): void {
    const { operations } = this.$store.state as State;
    if (newIdin && newIdin.Transaction && !newIdin.Transaction.Response
      && (operations.name === 'IDIN_TRANSACTION_SUCCESS' || operations.name === 'IDIN_TRANSACTION_PROCESSING')) {
      window.location.replace(newIdin.Issuer.issuerAuthenticationURL);
    }

    if (newIdin && newIdin.Transaction && !newIdin.Transaction.Response && !operations.name!.startsWith('IDIN_')) {
      this.idinGetStatus({ txId: newIdin.Transaction.transactionID });
    }
  }

  @Watch('issuerId')
  onIssuerIdChanged(newIssuerId: string, oldIssuerId: string): void {
    if (newIssuerId && !oldIssuerId) {
      this.showError = false;
    }
  }

  /**
   * Returns whether iDIN is loading/processing.
   *
   * @returns {boolean}
   */
  get identificationLoading(): boolean {
    return this.operations.name === 'IDIN_TRANSACTION_PROCESSING' || this.operations.name === 'IDIN_GET_STATUS_PROCESSING'
      || (this.operations.name === 'IDIN_GET_STATUS_SUCCESS' && !this.identificationComplete);
  }

  /**
   * Returns whether iDIN is pending, i.e.
   * still needs to be completed.
   *
   * @returns {boolean}
   */
  get identificationPending(): boolean {
    return !this.idin || !this.identificationComplete;
  }

  /**
   * Returns whether iDIN is complete.
   *
   * @returns {boolean}
   */
  get identificationComplete(): boolean {
    return this.isInvestor && !!this.idin && !!this.idin.Transaction && !!this.idin.Transaction.Response
      && Object.keys(this.idin.Transaction.Response).length > 0;
  }

  get redirect(): any {
    return this.$route.query.redirect;
  }

  get inCheckoutFlow(): boolean {
    return !!this.$router.currentRoute.params.investmentId;
  }

  get linkto(): any {
    return this.inCheckoutFlow
      ? `/checkout/investment/${this.$router.currentRoute.params.investmentId}`
      : this.redirect || this.$router.resolve({ name: 'properties' }).route.path;
  }

  get from(): any {
    return this.$route.query.from;
  }

  get returnURL(): string {
    return `${window.location.origin}/`;
  }

  /**
   * Handle transaction.
   *
   * @param {string} issuerId
   */
  handleIdinTransaction({ issuerId }): void {
    if (!issuerId) {
      this.showError = true;
    } else {
      let merchantReturnUrl = window.location.toString().includes('checkout') ?
        `${window.location.origin}/checkout/${IdentifcationPath}/${this.$router.currentRoute.params.investmentId}/idin` :
        `${window.location.origin}/${IdentifcationPath}/idin`;
      let params = 0;
      if (this.redirect) {
        merchantReturnUrl += `?redirect=${this.redirect}`;
        params++;
      }
      if (this.from) {
        merchantReturnUrl += `${!params ? '?' : '&'}from=${this.from}`;
      }
      this.idinTransaction({ issuerId, merchantReturnUrl });
    }
  }

  goTo(): void {
    const query: { [key: string]: any } = {};
    if (this.redirect) {
      query.redirect = this.redirect;
    }
    if (this.from) {
      query.from = this.from;
    }
    this.$router.push({
      name: IdentRouteNames.MAIN,
      query,
    }).catch((): void => {});
  }
}

import { render, staticRenderFns } from "./IdentificationBusiness.html?vue&type=template&id=1a9ab86a&scoped=true&"
import script from "./IdentificationBusiness.ts?vue&type=script&lang=ts&"
export * from "./IdentificationBusiness.ts?vue&type=script&lang=ts&"
import style0 from "./IdentificationBusiness.scss?vue&type=style&index=0&id=1a9ab86a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a9ab86a",
  null
  
)

export default component.exports